import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Text",
        "componentName": "Text"
      }}>{`<Text
  color="primaryBase"
  fontFamily="primary"
  size="Heading4"
  fontWeight="bold"
  decoration="underline"
>
  Hello there
</Text>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Text" mdxType="Props" />
    <h2 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}>{`Related components`}</h2>
    <p>{`While this `}<inlineCode parentName="p">{`Text`}</inlineCode>{` component is meant for ultimate flexibility when it comes to
text-related requirements, there are also other text-related predefined
components.`}</p>
    <p>{`These are:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/caption/code"
        }}>{`Caption`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/heading/code"
        }}>{`Heading`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/paragraph/code"
        }}>{`Paragraph`}</a></li>
    </ul>
    <p>{`The reason these separate components also exist, is purely for developer
experience. The above components can be constructed by combining the necessary
parameters on the `}<inlineCode parentName="p">{`Text`}</inlineCode>{` component (internally, the above components are an
extension of the `}<inlineCode parentName="p">{`Text`}</inlineCode>{` component). However, they have default behaviour built
in, e.g. the `}<inlineCode parentName="p">{`Heading`}</inlineCode>{` component will automatically add a bold `}<inlineCode parentName="p">{`fontWeight`}</inlineCode>{` and
will change its HTML element based in the `}<inlineCode parentName="p">{`size`}</inlineCode>{`, etc.`}</p>
    <h2 {...{
      "id": "size",
      "style": {
        "position": "relative"
      }
    }}>{`Size`}</h2>
    <p><inlineCode parentName="p">{`default: Heading6`}</inlineCode></p>
    <p>{`The size property corresponds to all the possible sizes defined in the
typography foundations.`}</p>
    <p>{`The default here is `}<inlineCode parentName="p">{`Heading6`}</inlineCode>{`. This size is the best default to fall back to,
since it has an average font-size and corresponding line-height. The difference
with `}<inlineCode parentName="p">{`Paragraph`}</inlineCode>{` is not the font-size, but rather the line-height. `}<inlineCode parentName="p">{`Paragraph`}</inlineCode>{`
size has a line-height that is 1.5 times the font-size, which improves
readability in paragraphs of text, but is not suited as a default size.`}</p>
    <p><strong parentName="p">{`NOTE:`}</strong>{` The size does `}<strong parentName="p">{`not`}</strong>{` translate to a certain HTML element. If you want
to customize the HTML element, use the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop as well.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Text size="Heading1" as="div">Heading 1 size</Text>
<Text size="Heading2" as="div">Heading 2 size</Text>
<Text size="Heading3" as="div">Heading 3 size</Text>
<Text size="Heading4" as="div">Heading 4 size</Text>
<Text size="Heading5" as="div">Heading 5 size</Text>
<Text size="Heading6" as="div">Heading 6 size</Text>
<Text size="Paragraph" as="div">Paragraph size</Text>
<Text size="Caption1" as="div">Caption 1 size</Text>
<Text size="Caption2" as="div">Caption 2 size</Text>
`}</code></pre>
    <h2 {...{
      "id": "fontfamily",
      "style": {
        "position": "relative"
      }
    }}>{`FontFamily`}</h2>
    <p><inlineCode parentName="p">{`default: inherit`}</inlineCode></p>
    <p>{`Font-families can be used to discern between multiple uses.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Text fontFamily="primary" as="div">Primary font-family</Text>
<Text fontFamily="secondary" as="div">Secondary font-family</Text>
<Text fontFamily="system" as="div">System font-family</Text>
`}</code></pre>
    <h2 {...{
      "id": "texttransform",
      "style": {
        "position": "relative"
      }
    }}>{`TextTransform`}</h2>
    <p><inlineCode parentName="p">{`default: inherit`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Text textTransform="none" as="div">A Text component</Text>
<Text textTransform="capitalize" as="div">A Text component</Text>
<Text textTransform="uppercase" as="div">A Text component</Text>
<Text textTransform="lowercase" as="div">A Text component</Text>
`}</code></pre>
    <h2 {...{
      "id": "textalign",
      "style": {
        "position": "relative"
      }
    }}>{`TextAlign`}</h2>
    <p><inlineCode parentName="p">{`default: inherit`}</inlineCode></p>
    <p>{`Controls the css text-align attribute.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Text textAlign="left" as="div">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis id ex ac erat malesuada vestibulum pretium et ex. Integer nec magna at mi venenatis sagittis. Maecenas suscipit erosegeterat feugiat, et ultrices purus condimentum. Praesent bibendum, elit a aliquam vehicula, magna metus accumsan lectus, eu pulvinar sapien tellus in.</Text>
<Text textAlign="right" as="div">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis id ex ac erat malesuada vestibulum pretium et ex. Integer nec magna at mi venenatis sagittis. Maecenas suscipit erosegeterat feugiat, et ultrices purus condimentum. Praesent bibendum, elit a aliquam vehicula, magna metus accumsan lectus, eu pulvinar sapien tellus in.</Text>
<Text textAlign="center" as="div">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis id ex ac erat malesuada vestibulum pretium et ex. Integer nec magna at mi venenatis sagittis. Maecenas suscipit erosegeterat feugiat, et ultrices purus condimentum. Praesent bibendum, elit a aliquam vehicula, magna metus accumsan lectus, eu pulvinar sapien tellus in.</Text>
<Text textAlign="justify" as="div">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis id ex ac erat malesuada vestibulum pretium et ex. Integer nec magna at mi venenatis sagittis. Maecenas suscipit erosegeterat feugiat, et ultrices purus condimentum. Praesent bibendum, elit a aliquam vehicula, magna metus accumsan lectus, eu pulvinar sapien tellus in.</Text>
`}</code></pre>
    <h2 {...{
      "id": "fontweight",
      "style": {
        "position": "relative"
      }
    }}>{`FontWeight`}</h2>
    <p><inlineCode parentName="p">{`default: inherit`}</inlineCode></p>
    <p>{`Controls the font-weight css attribute. Only 3 values are available. If a value
is missing, it will fallback on the more bold weight.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Text fontWeight="regular" as="div">Regular font-weight</Text>
<Text fontWeight="medium" as="div">Medium font-weight</Text>
<Text fontWeight="bold" as="div">Bold font-weight</Text>
`}</code></pre>
    <h2 {...{
      "id": "decoration",
      "style": {
        "position": "relative"
      }
    }}>{`Decoration`}</h2>
    <p><inlineCode parentName="p">{`default: inherit`}</inlineCode></p>
    <p>{`Controls the text-decoration css attribute.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Text decoration="none" as="div">No text-decoration</Text>
<Text decoration="underline" as="div">Underline text-decoration</Text>
<Text decoration="line-through" as="div">Line-through text-decoration</Text>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      